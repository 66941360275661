import React from 'react'
import {
  Cog8ToothIcon,
  CommandLineIcon,
  PresentationChartLineIcon,
  ChatBubbleOvalLeftEllipsisIcon
} from '@heroicons/react/24/outline'

const valuePropositions = [
  {
    name: 'Develop at speed',
    description:
      'We understand the importance of having a website up and running so you can focus on what\'s important to you - your customers.',
    icon: CommandLineIcon,
  },
  {
    name: 'Reliable support',
    description:
      'Managing a website should be hassle-free. Any changes made to our clients website undergo a quality check to ensure it passes a set of comprehensive standards.',
    icon: Cog8ToothIcon,
  },
  {
    name: 'No bells and whistles',
    description:
      'Many website builders upsell plugins or functionality you won\'t actually need or use. We believe in being transparent to support local businesses.',
    icon: ChatBubbleOvalLeftEllipsisIcon,
  },
  {
    name: 'Built to last',
    description:
      'We know a website is an important channel to bring in existing and new business. Our solutions are pragmatic, ensuring you get value from your technology investments.',
    icon: PresentationChartLineIcon,
  }
]

const ValueProp = () => (
  <div className="bg-white pt-10">
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="lg:text-center">
        <small className="text-lg font-semibold text-primary-400 dark:text-gray-400">Build with confidence</small>
        <h2 className="min-w-full mt-2 text-3xl font-bold leading-8 tracking-tight text-primary-800 sm:text-4xl">
          Why choose us?
        </h2>
        <p className="mt-4 max-w-full text-lg text-gray-500 lg:mx-auto lg:text-base">
          Here are are some of the reasons why we believe we are uniquely positioned compared to other website builders in the market.
        </p>
      </div>
      <div className="mt-10">
        <dl className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-8 lg:px-16">
          {valuePropositions.map((dotpoint) => (
            <div key={dotpoint.name} className="relative">
              <dt>
                <div className="absolute flex h-12 w-12 rounded-full items-center justify-center bg-gradient-to-bl from-secondary-400 to-primary-400 text-white">
                  <dotpoint.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="ml-16 text-lg font-semibold leading-6 text-primary-800">{dotpoint.name}</p>
              </dt>
              <dd className="mt-2 ml-16 text-lg text-gray-500 lg:text-base">{dotpoint.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  </div>
)

export default ValueProp
