import React from "react"
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import {
  CogIcon,
  BuildingOffice2Icon
} from '@heroicons/react/24/outline'
import Layout from "../components/layout"
import Navbar from "../components/nav"
import ValueProp from '../components/valueProposition'
import Quote from '../components/Quote'
import CallToAction from '../components/CallToAction'

const theme = 'dark'
const logo = <StaticImage
  src="../images/darkLogo.svg"
  alt="Workflow"
  placeholder="none"
  loading="eager"
  height={50}
/>

const IndexPage = () => {
  return (
    <Layout
      title="Dimension Lab | Boutique Web Designer"
      description="We drive businesses and communities forward with modern web technology solutions, and enable businesses to focus on what matters to them."
      pageUrl="/"
      theme={theme}
      logo={logo}
    >
      <div>
        <Navbar theme={theme} logo={logo} />
        <div className="relative overflow-hidden bg-gradient-to-t from-secondary-800 to-primary-800">
          {
            // Hero Text
            <div className="mx-auto max-w-7xl">
              <div className="relative z-1 pb-8 sm:pb-12 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-40">
                <div className="mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                  <div className="sm:text-center lg:text-left lg:pr-24">
                    <small className="text-lg font-semibold text-gray-200">Dimension Lab</small>
                    <h1 className="text-4xl font-bold tracking-tight text-primary-800 mt-2 mb-0 sm:mb-8 sm:text-5xl md:text-6xl">
                      <span className="xl:inline text-white">Build with</span>{' '}
                      <span className="text-primary-400 xl:inline">speed</span>{' '}
                      <span className="xl:inline text-white">and</span>{' '}
                      <span className="text-secondary-400 xl:inline">agility</span>
                    </h1>
                    <p className="mt-3 text-base font-normal text-gray-200 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-lg lg:mx-0 lg:mr-6">
                      Supercharge your next website with the solutions of tomorrow.
                    </p>
                    <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                      <div className="rounded-lg shadow">
                        <Link
                          to="/contact-us"
                          className="no-underline flex w-full items-center justify-center rounded-lg border border-transparent bg-primary-600 px-8 py-3 text-base font-medium text-white hover:bg-primary-400 md:py-4 md:px-8 md:text-lg"
                        >
                          Request a Quote
                        </Link>
                      </div>
                      <div className="rounded-lg shadow mt-3 sm:mt-0 sm:ml-3">
                        <Link
                          to="/about-us"
                          className="no-underline flex w-full items-center justify-center rounded-lg border border-transparent bg-secondary-400 text-white hover:bg-secondary-200 px-8 py-3 text-base font-medium md:py-4 md:px-12 md:text-lg"
                        >
                          Who We Are
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {
            // Hero Background
            <div className="flex items-center justify-center px-10 md:px-32 lg:px-0 lg:justify-end lg:absolute lg:top-10 lg:right-10 lg:w-1/3 xl:top-5 xl:right-24 xl:w-1/4">
              <StaticImage
                src="../images/mockup-2.png"
                alt="A Sample Mock Up provided by Dimension Lab"
                placeholder="none"
                loading="eager"
                objectFit="constrained"
                className="w-6/12 lg:w-auto"
              />
            </div>
          }
        </div>

        <div className="relative overflow-hidden pt-8 bg-gradient-to-t from-secondary-400 to-secondary-800 lg:pb-0">
          <div className="relative">
            <div className="
            lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8
          ">
              <div className="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-10 lg:px-0">
                <div>
                  <span className="flex h-12 w-12 items-center justify-center rounded-md bg-primary-200">
                    <CogIcon className="h-6 w-6 text-primary-100" aria-hidden="true" />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-bold tracking-tight text-white">
                    <span>We</span>{' '}
                    <span className="text-primary-200">understand technology...</span>
                  </h2>
                  <p className="mt-4 text-lg text-gray-200">
                    We have built many websites at scale with our clients. We are just as invested as you in ensuring longevity of your success for the near and long-term future, putting your business at the heart of everything we build.
                  </p>
                </div>
              </div>
              <div>
                <div className="flex justify-center lg:relative h-auto lg:mt-16">
                  <StaticImage
                    src="../images/frame.svg"
                    alt="An Empty Wireframe"
                    placeholder="blurred"
                    loading="eager"
                    objectFit="constrained"
                    className="w-1/2 -mb-10 lg:absolute lg:top-0 lg:mb-0 lg:right-0 lg:w-auto lg:max-w-none"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="relative">
            <div className="
            lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8
            lg:pb-10
          ">
              <div className="mx-auto max-w-xl px-4 sm:px-6 lg:col-start-2 lg:mx-0 lg:py-10 lg:max-w-none lg:px-0">
                <div>
                  <span className="flex h-12 w-12 items-center justify-center rounded-md bg-secondary-200">
                    <BuildingOffice2Icon className="h-6 w-6 text-secondary-600" aria-hidden="true" />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-bold tracking-tight text-white">
                    <span>...and are</span>{' '}
                    <span className="text-secondary-200">invested</span>{' '}
                    <span>into your success through our</span>{' '}
                    <span className="text-secondary-200">partnership</span>{' '}
                  </h2>
                  <p className="mt-4 text-lg text-gray-200">
                    Your requirements will change as your business grows. We want to be known as the strategic provider you will
                    depend on as you extend and augment your business over time.
                  </p>
                  {/* <div className="mt-6">
                    <Link
                      to="#"
                      className="no-underline inline-flex rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-gray-100 shadow-sm hover:bg-primary-400 hover:text-white"
                    >
                      Find More
                    </Link>
                  </div> */}
                </div>
              </div>
              <div>
                <div className="flex justify-center lg:relative overflow-visible">
                  <StaticImage
                    src="../images/rocket.svg"
                    alt="A Rocketship"
                    placeholder="blurred"
                    loading="eager"
                    objectFit="constrained"
                    className="w-1/3 lg:absolute lg:left-0 lg:w-1/2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ValueProp />

      <Quote />

      <CallToAction />

    </Layout>
  )
}

export default IndexPage
