import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

const CallToAction = () => (
  <div className="bg-gradient-to-tl from-primary-100 to-secondary-200">
    <div className="mx-auto grid sm:grid-cols-2 px-8 py-12 max-w-7xl lg:pt-20">
      <div className='w-auto'>
        <h2 className="py-1 font-bold text-3xl tracking-tight text-primary-800 lg:text-4xl">Ready for a fresh look?</h2>
        <h2 className="py-1 font-bold text-3xl tracking-tight text-primary-400 lg:text-4xl">Get in touch with us.</h2>
        <div className="mt-4 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <Link
              to="/contact-us"
              className="
                    inline-flex items-center justify-center
                    font-medium text-lg text-white no-underline
                    bg-primary-600 hover:bg-primary-400
                    px-5 py-3 lg:px-6 lg:py-4
                    border border-transparent rounded-md
                  "
            >
              Get a Quote
            </Link>
          </div>
        </div>
      </div>
      <div className='hidden md:flex md:justify-end md:w-auto'>
        <StaticImage
          src="../images/rocket2.svg"
          alt='Rocket'
          placeholder="blurred"
          loading="eager"
          objectFit="constrained"
          className="w-4/12 lg:w-3/12"
        />
      </div>
    </div>
  </div>
)

export default CallToAction
